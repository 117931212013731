<template>
  <div class="workspaces">
    <template v-if="loadWorkspace">
      <CircleLoader :ratio="1.5" class="loader"/>
    </template>
    <template v-else>
      <template v-for="role in ['owner', 'collaborator']">
        <template v-if="workspaces[role].length > 0">
          <h2 v-if="role !== 'owner'" class="role-title">{{role[0].toUpperCase() + role.slice(1) + ':'}}</h2>
          <template v-for="workspace in workspaces[role]" :key="'workspace-cards-item-' + workspace.id">
            <WorkspaceCard :workspace="workspace" :isOwner="role === 'owner'"/>
          </template>
        </template>
        <template v-else-if="role === 'owner'">
          <div class="create-new-wrapper">
            <IconNew name="auth-promo-canvas" class="create-new-icon"/>
            <p>You haven't created a workspace yet.<br>You can do it now.</p>
            <ButtonNew size="l" text="Create" mode="ghost" class="create-new-button" @click="addNewWorkspace"/>
          </div>
        </template>
      </template>
    </template>
  </div>

</template>

<script>
import {reactive, ref, computed, watch, toRefs, inject} from "vue";
import ButtonNew from "@/components/UI-Kit/ButtonNew";
import Modal from "@/components/UI-Kit/Modal";
import TextInput from "@/components/UI-Kit/TextInput";
import TextInputNew from "@/components/UI-Kit/TextInputNew";
import TextArea from "@/components/UI-Kit/TextArea";
import Avatar from "@/components/UI-Kit/Avatar";
import AvatarEditor from "@/components/UI-Kit/AvatarEditor";
import WorkspaceCard from "@/components/Account/WorkspaceCard";
import CircleLoader from "@/components/UI-Kit/loaders/CircleLoader";
import IconNew from "@/components/UI-Kit/IconNew";
import { useRouter } from "vue-router"

const workspaceSetup = function(props) {
  const router = useRouter()
  const workspaces = inject("workspaces")
  const loadWorkspace = inject("loadWorkspaces")

  const addNewWorkspace = function () {
    router.push({
      name: "Onboarding"
    })
  }

  return {
    workspaces,
    addNewWorkspace,
    loadWorkspace,
  }

}

export default {
  name: "AccountWorkspaces",
  components: {
    IconNew,
    CircleLoader, WorkspaceCard, AvatarEditor, Avatar, TextArea, TextInputNew, TextInput, Modal, ButtonNew},
  props: {
    workspaceId: String
  },
  setup: workspaceSetup
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme.scss";
.new-workspace-avatar-label {
  color: #333333;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0;

}

.role-title {
  margin: 20px 0 15px 0!important;
}

.loader {
  margin: 40px;
}

.create-new-button {
  margin: 20px auto;
  width: 120px;
  display: block;
}
.create-new-wrapper {
  width: 100%;
  border: 3px dashed $pxp-gray-accent-color-dimmer;
  margin-top: 20px;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    text-align: center;

    color: #13246E;
  }
}
.create-new-icon {
  width: 103px;
  height: 80px;
  justify-self: center;
  display: block;
  margin: 20px auto;
}
</style>